import { useEffect, useState } from 'react';
import '../assets/css/Home.css';
import milles_logo from '../assets/img/milles_logo.svg';
import pil from '../assets/img/pil.svg';
import axios from 'axios';

interface ContactInfo {
	address: string;
	city: string;
	email: string;
	id: number;
	instagram: string;
	phone: string;
	zip_code: string;
}

interface OpeningHours {
	id: number;
	day_interval: string;
	hours: string;
}

interface HomePageContent {
	book_table_cta: string;
	menu_text: string;
	open_hours_heading: string;
	contact_info: ContactInfo;
	opening_hours: OpeningHours[];
	menu_url?: string;
	createdAt: Date;
	publishedAt: Date;
	updatedAt: Date;
}

export const Home = () => {
	const [homePageContent, setHomePageContent] = useState<HomePageContent | null>(null);

	useEffect(() => {
		const fetchHomePageContent = async () => {
			try {
				const response = await axios.get(
					process.env.REACT_APP_STRAPI_URL + '/api/home-page',
					{
						params: {
							populate: 'opening_hours, contact_info, menu',
						},
					}
				);

				const menu_url: string =
					process.env.REACT_APP_STRAPI_URL +
					response.data.data.attributes.menu.data.attributes.url;

				const homePageContent: HomePageContent = {
					book_table_cta: response.data.data.attributes.book_table_cta,
					menu_text: response.data.data.attributes.menu_text,
					open_hours_heading: response.data.data.attributes.open_hours_heading,
					contact_info: response.data.data.attributes.contact_info,
					opening_hours: response.data.data.attributes.opening_hours,
					menu_url: menu_url,
					createdAt: response.data.data.attributes.createdAt,
					publishedAt: response.data.data.attributes.publishedAt,
					updatedAt: response.data.data.attributes.updatedAt,
				};
				setHomePageContent(homePageContent);
			} catch (error) {
				console.log(error);
			}
		};

		fetchHomePageContent();
	}, []);

	return (
		<main className="home__wrapper">
			<section className="container">
				<div className="home__content">
					<img src={milles_logo} alt="Milles logo" className="milles__logo" />
					<div className="open__hours__wrapper">
						<h2>{homePageContent?.open_hours_heading || 'Öppettider'}</h2>
						<div className="open__hours">
							{homePageContent?.opening_hours.map((hours, index) => (
								<div key={index}>
									<p>{hours.day_interval || 'Dag'}</p>
									<p>{hours.hours || '00:00 - 00:00'}</p>
								</div>
							)) || (
								<>
									<div>
										<p>Mån</p>
										<p>11:30 - 16:00</p>
									</div>
									<div>
										<p>Tis - Tor</p>
										<p>11:30 - 23:00</p>
									</div>
									<div>
										<p>Fre - Lör</p>
										<p>11:30 - 00:00</p>
									</div>
								</>
							)}
						</div>
					</div>
					<div className="menu">
						<a
							href={homePageContent?.menu_url || '/meny.pdf'}
							target="_blank"
							rel="noopener noreferrer"
						>
							{homePageContent?.menu_text || 'Meny'}
						</a>
					</div>
					<div className="book__table">
						<a
							href="https://app.waiteraid.com/reservation/?hash=0d382f6f35b0bfa69542d81a04a50e24"
							target="_BLANK"
							rel="noreferrer"
							className="book__link"
						>
							{homePageContent?.book_table_cta || 'Boka bord'}
							<img src={pil} alt="Pil" className="pil" height="20" />
						</a>
					</div>
					<div className="contact__info">
						<div>
							<a href="https://www.google.com/maps/place/Restaurang+Milles/@59.3326728,18.0753743,17z/data=!3m1!4b1!4m6!3m5!1s0x465f9d5a485d182b:0x1b0dae7db5bc9bc3!8m2!3d59.3326701!4d18.0779546!16s%2Fg%2F1pp2vkq40?entry=ttu" target="_BLANK" rel="noreferrer">
								{homePageContent?.contact_info.address || 'Strandvägen 1'}
							</a>
							<a href="https://www.google.com/maps/place/Restaurang+Milles/@59.3326728,18.0753743,17z/data=!3m1!4b1!4m6!3m5!1s0x465f9d5a485d182b:0x1b0dae7db5bc9bc3!8m2!3d59.3326701!4d18.0779546!16s%2Fg%2F1pp2vkq40?entry=ttu" target="_BLANK" rel="noreferrer">
								{homePageContent?.contact_info.zip_code || '114 51'}{' '}
								{homePageContent?.contact_info.city || 'Stockholm'}
							</a>
						</div>
						<div>
							<a href={`tel:${homePageContent?.contact_info.phone || '+4686638002'}`}>
								{homePageContent?.contact_info.phone || '+46 8 663 80 02'}
							</a>
							<a
								href={`mailto:${homePageContent?.contact_info.email || 'info@mmilles.se'}`}
								className="email"
							>
								{homePageContent?.contact_info.email || 'info@mmilles.se'}
							</a>
						</div>
						<div>
							<span>&nbsp;</span>
							<a
								href={homePageContent?.contact_info.instagram || 'https://www.instagram.com/michellevandermilles/'}
								target="_BLANK"
								rel="noreferrer"
							>
								instagram
							</a>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
};
